@media (max-width: 900px) {
    .login-left {
      display: none;
    }
  
    .register-left {
      display: none;
    }
  }
  
  @media (max-width: 600px) {
    .dashboard-main {
      font-size: 20px !important;
    }
  
    .landing-main {
      font-size: 20px !important;
    }
  
    .logout-button {
      width: 300px;
    }
  
    .landing-register-button {
      width: 300px !important;
    }
  
    .landing-login-button {
      width: 300px !important;
    }
  
    .dashboard-main p{
      font-size: 15px;
    }
  }
  
  @media (max-width: 400px) {
    .dashboard-main {
      font-size: 15px !important;
    }
  
    .landing-main {
      font-size: 15px !important;
    }
  
    .logout-button {
      padding: 12px;
    }
  
    .landing-register-button {
      padding: 8px !important;
      width: 250px !important;
    }
  
    .landing-login-button {
      padding: 8px !important;
      width: 250px !important;
    }
  }
  
  @media (max-width: 370px) {
    .login-center-options {
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
    }
  
    .login-center-buttons {
      margin-top: 20px;
    }
  
    .login-center h2 {
      font-size: 30px;
    }
  
    .login-center p {
      font-size: 15px;
    }
  
    .register-center-options {
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
    }
  
    .register-center-buttons {
      margin-top: 20px;
    }
  
    .register-center h2 {
      font-size: 30px;
    }
  
    .register-center p {
      font-size: 15px;
    }
  }
  