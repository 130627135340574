.logout-main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f0f0;
    text-align: center;
    position: relative;
    direction: rtl;
  }
  
  .logout-box {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    width: 300px;
  }
  
  .logout-main h1 {
    font-size: 2.5rem;
    color: #3C91E6;
    margin-bottom: 20px;
    font-weight: bold;
  }
  
  .logout-main p {
    font-size: 1.2rem;
    color: #555;
  }
  
  .logout-logo {
    margin-bottom: 20px;
  }
  
  .logout-logo img {
    width: 100px; /* أو حجم آخر حسب احتياجك */
  }
  
  .logout-main::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background-color: #28a745;
    clip-path: polygon(100% 0%, 0% 100%, 100% 100%);
    z-index: -1;
  }
  
  .logout-main h1,
  .logout-main p {
    z-index: 1;
    animation: fadeIn 1s ease-in-out both;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  