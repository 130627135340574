
.register-main{
    display: flex;
  }
  
  .register-left{
    flex-grow: 1;
    height: 100vh;
    background-color: #E9E9E9;
  
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .register-left img{
    width: 400px;
  }
  
  .register-right{
  
    height: 100vh;
    direction: rtl;
    flex-grow: 1;
  }
  
  .register-right-container{
    height: 100%;
    width: 80%;
    margin: 0 auto;
  
    display: flex;
    flex-direction: column;
    justify-content: center;
  
  }
  
  .register-right form{
    display: flex;
    flex-direction: column;
  }
  
  .register-logo{
    align-self: center;
    padding-top: 15px;;
  }
  
  .register-center{
    margin: auto 0;
  }
  
  .register-logo img{
    width: 100px;
  }
  
  .register-center h2{
    font-size: 35px;
  }
  
  .register-center{
    text-align: center;
  }
  
  .register-center p{
    font-weight: 400;
    font-size: 20px;
    margin-bottom: 40px;
  }
  
  .register-center input[type="text"],
  .register-center input[type="email"],
  .register-center input[type="password"],
  .register-center select {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    box-sizing: border-box;
  }
  
  .pass-input-div {
    position: relative;
  }
  
  .pass-input-div input {
    padding-right: 40px;
  }
  
  .pass-input-div svg {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
    color: #999;
  }
  
  .register-center-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .register-center-buttons button {
    background-color: #318ae4;
    color: #fff;
    border: none;
    padding: 12px;
    margin-bottom: 15px;
    font-size: 16px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease;
  }
  
  .register-center-buttons button:hover {
    background-color: #043361;
  }
  
  .register-center-buttons button img {
    width: 20px;
    margin-right: 10px;
    vertical-align: middle;
  }
  
  .pass-input-div{
    position: relative;
  }
  
  .pass-input-div svg{
    font-size: 20px;
    position: absolute;
    right: 95%;
    bottom: 35px;
    cursor: pointer;
    outline: none;
  }
  

  
  .register-bottom-p{
    text-align: center;
    font-size: 15px;
    padding-bottom: 40px;
  }
  
  .register-bottom-p a{
    text-decoration: none;
    font-weight: 600;
  }
  
  .register-bottom-p a:hover{
    text-decoration: underline;
  }
  
  @media (max-width: 900px) {
    .login-left {
      display: none;
    }
  
    .register-left {
      display: none;
    }
  }
  
  @media (max-width: 600px) {
    .dashboard-main {
      font-size: 20px !important;
    }
  
    .landing-main {
      font-size: 20px !important;
    }
  
    .logout-button {
      width: 300px;
    }
  
    .landing-register-button {
      width: 300px !important;
    }
  
    .landing-login-button {
      width: 300px !important;
    }
  
    .dashboard-main p{
      font-size: 15px;
    }
  }
  
  @media (max-width: 400px) {
    .dashboard-main {
      font-size: 15px !important;
    }
  
    .landing-main {
      font-size: 15px !important;
    }
  
    .logout-button {
      padding: 12px;
    }
  
    .landing-register-button {
      padding: 8px !important;
      width: 250px !important;
    }
  
    .landing-login-button {
      padding: 8px !important;
      width: 250px !important;
    }
  }
  
  @media (max-width: 370px) {
    .login-center-options {
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
    }
  
    .login-center-buttons {
      margin-top: 20px;
    }
  
    .login-center h2 {
      font-size: 10px;
    }
  
    .login-center p {
      font-size: 15px;
    }
  
    .register-center-options {
      flex-direction: column;
      align-items: center;
      row-gap: 10px;
    }
  
    .register-center-buttons {
      margin-top: 20px;
    }
  
    .register-center h2 {
      font-size: 30px;
    }
  
    .register-center p {
      font-size: 15px;
    }
  }
  