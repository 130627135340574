html {
    font-size: 100%;
    overflow-x: hidden;
  }

  @media (max-width: 991px) {
    html {
      font-size: 90%;
    }
  }

  @media (max-width: 768px) {
    html {
      font-size: 85%;
    }
  }
  @media (max-width: 450px) {
    html {
      font-size: 80%;
    }
  }