.eval-section {
    direction: rtl; /* دعم النصوص باللغة العربية */
    text-align: center;
    padding: 50px;
    background-color: var(--black);
    border-radius: 10px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.537);
    max-width: 800px;
    margin: 40px auto;
    font-family: 'Cairo', sans-serif;
    color: var(--waite);
  }
  
  .eval-section h1 {
    font-size: 2.5rem;
    color: #2980b9;
    margin-bottom: 20px;
  }
  
  .eval-section p {
    font-size: 1.2rem;
    color: var(--black);
    line-height: 1.8;
    margin-bottom: 15px;
  }
  
  .eval-section p:last-child {
    margin-top: 30px;
    font-weight: 600;
    color: var(--black);
  }
  
  @media (max-width: 768px) {
    .eval-section {
      padding: 30px;
    }
  
    .eval-section h1 {
      font-size: 2rem;
    }
  
    .eval-section p {
      font-size: 1rem;
    }
  }
  