.account-form1 {
  position: relative;
  align-items: center;
  width: 100%;
  max-width: 500px;
  margin-top: 40px;
  background: linear-gradient(135deg, var(--black), #3C91E6);
  display: flex;
  flex-flow: column;
  gap: 1rem;
  justify-content: center;
  z-index: 1200;
  padding: 1rem; /* Increased padding for better spacing */
  border-radius: 15px;
  text-align: right;
  direction: rtl;
  box-shadow: 0 10px 30px rgba(98, 97, 97, 0.718);
  }
  
  .register-form h3 {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
  }
  
  
  .register-form select, input{
    width: 100%;
    padding: 16px;
    font-size: 17px;
    margin-bottom: 5px;
    border: 0px;
    border-bottom: 1.5px solid black;
    outline: none;
    box-sizing: border-box;
  }
  
  
  select:focus {
    border: 2px solid #3C91E6;
    outline: none; /* Remove default outline */
  }
  input:focus {
    border: 2px solid #3C91E6;
    outline: none; /* Remove default outline */
  }
  
  .register-form button.btn {
    padding: 1rem;
    background-color: #3C91E6;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    font-size: 1.2rem;
  }
  
  .register-form button.btn:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .invalid select, .invalid button.btn {
    border-color: red;
  }
  
  .invalid label {
    color: red;
  }
  /* FormPage.css */
  .time-input-container {
    margin-bottom: 15px;
  }
  
  .time-input-container label {
    display: block;
    margin-bottom: 5px;
    text-decoration: underline;
    color: rgb(170, 11, 11);
  }
  
  .shift-select-container {
    margin-bottom: 15px;
  }
  
  .shift-select-container label {
    display: block;
    margin-bottom: 5px;
  
  }
  
  @media (max-width: 550px) {
    .register-form h3{
        font-size: 10px;
        text-align: center;
        margin-bottom: 5px;
      }  
  }
  