
 .body{
     position: relative;
     font-family: 'Nunito', 'Lateef', 'Nunito', sans-serif;
     unicode-bidi: bidi-override;
     width: 95%;
     justify-items: center;
     font-size: 12;
  color: var(--black);
  margin: 10px;
  
  }
  .loading-message {
   display: flex;
   align-items: center;
   justify-content: center;
   height: 100vh; /* يجعل الرسالة في منتصف الشاشة */
   font-size: 24px; /* حجم خط أكبر */
   color: #333; /* لون نص داكن */
   font-weight: bold; /* جعل الخط غامق */
   background: linear-gradient(90deg, #f0f0f0, #e0e0e0); /* خلفية متدرجة */
   border-radius: 8px; /* زوايا مستديرة */
   box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* ظل خفيف */
 }
 
  
  .parent{
  
     width: 100%;
     height: 100%;
     gap: 2px;
     display: grid;
     grid-template-columns: repeat(1,auto);
  text-align: center;
  background-color: var(--white);
  margin: 5px;
  font-size: large;
  
     
  }
  
  .parent .block1{
     display: flex;
     justify-content: center;
     width: 100%;
     overflow: hidden;
     margin: 2mm;
  }
  .blockf2{
     font-weight: bold;
     font-size: 18;
  }
  .blockf1{
     font-weight: bold;
     font-size: 15;
  
  }
  .block1 .block {
     float: right;
     width: 33%;
     box-sizing: border-box;
  
  }
  .blockimg{
     width: 47%;
  }
  
  .timetable{
     width: 100%;
     text-align: center;
     border-collapse: collapse;
     border-color: var(--black);
     
     
     }
     .timetable th, .timetable td{
     font-family: "Lateef", serif;
         border: solid 1px;
         border-color: var(--black);
         direction: rtl;
         
     }
  .stylemploi{
  font-size: x-large;
  font-weight: bold;
  }
  
    .timetable #T1{
     background-color: rgba(154, 204, 239, 0.432);
     writing-mode: sideways-lr;
     }
     .timetable #T2{
         background-color: rgba(230, 171, 125, 0.522);
     writing-mode: sideways-lr;
  
         }
     
     .timetable #T11{
        background-color: rgba(242, 253, 243, 0.435);
    }
    .timetable #T12{
     background-color: rgba(233, 237, 166, 0.413);
    }
    .timetable #T13{
     background-color: rgba(230, 186, 233, 0.329);
    }
  .block4{
     margin-top: 5mm;
  }
  .td td{
     height: 15mm;
  }
  .OT td{
     height: 5mm;
  }
  
   
   @media (max-width:855px){
   
      .body{
         font-size: 95%;
 
      }
      .parent{
 
         font-size: 100%;
     }
     .blockf2{
         font-size: 12px;
     }
     .blockf1{
         font-size: 11.5px;
     }
     .stylemploi{
         font-size: 16px;
     }
   
   }
   
   @media (max-width:638px){
   
     .body{
         font-size: 95%;
         width: 95%;
      }
      .parent{
 
         font-size: 90%;
         margin: 2px 1px;
 
     }
     .blockf2{
         font-size: 10px;
     }
     .blockf1{
         font-size: 9px;
     }
     .stylemploi{
         font-size: 12px;
     }
      
  }
  @media (max-width:536px){
   
     .body{
         font-size: 68%;
      }
      .parent{
 
         font-size: 80%;
         margin: 2px 1px;
     }
     .blockf2{
         font-size: 8.2px;
     }
     .blockf1{
         font-size: 6.8px;
     }
      
  }