.intro-section {
    direction: rtl; /* Arabic text support */
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(135deg, var(--black), #e0e0e0);
    border-radius: 15px;
    box-shadow: 0 10px 30px rgba(98, 97, 97, 0.718);
    max-width: 900px;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
    color: var(--waite);
  }
  
  .intro-section h1 {
    font-size: 2.8rem;
    color: var(--waite);
    margin-bottom: 25px;
    font-weight: 700;
  }
  
  .intro-section p {
    font-size: 1.2rem;
    color: var(--waite);
    line-height: 1.7;
    margin-bottom: 20px;
    max-width: 850px;
    margin: 0 auto 25px;
  }
  
  .intro-section ul {
    list-style: none;
    padding: 0;
    margin: 30px 0;
  }
  
  .intro-section ul li {
    font-size: 1.1rem;
    color: var(--waite);
    padding: 15px 20px;
    background-color: var(--waite);
    border-radius: 8px;
    margin-bottom: 10px;
    box-shadow: 0 5px 15px rgba(98, 97, 97, 0.718);
    transition: all 0.3s ease;
  }
  
  .intro-section ul li:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  }
  
  .intro-section p:last-child {
    font-style: italic;
    color: #95a5a6;
    margin-top: 25px;
  }
  
  /* Specific styles for the Terms of Use page */
.terms-of-use {
  padding: 40px 0;
  background: #f9f9f9;
  direction: rtl; /* Right-to-left layout for Arabic */
}

.terms-of-use .container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.terms-of-use h1.about-title {
  font-size: 2.5em;
  margin-bottom: 20px;
  text-align: center;
  color: #2c3e50;
}

.terms-of-use h2.about-title1 {
  font-size: 1.8em;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #34495e;
}

.terms-of-use p {
  margin-bottom: 20px;
  line-height: 1.8;
  color: #7f8c8d;
  font-size: 1.1em;
}

.terms-of-use a {
  color: #3498db;
  text-decoration: none;
}

.terms-of-use a:hover {
  text-decoration: underline;
}

.terms-of-use ul {
  margin-bottom: 20px;
  padding-left: 25px;
  color: #555;
  list-style: disc;
}

.terms-of-use ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
  color: #7f8c8d;
}

@media (max-width: 768px) {
  .terms-of-use .container {
      padding: 15px;
  }

  .terms-of-use h1.about-title {
      font-size: 2em;
  }

  .terms-of-use h2.about-title1 {
      font-size: 1.5em;
  }

  .terms-of-use p {
      font-size: 1em;
  }
}

  @media (max-width: 768px) {
    .intro-section h1 {
      font-size: 2rem;
    }
  
    .intro-section p {
      font-size: 1rem;
    }
  
    .intro-section ul li {
      font-size: 1rem;
    }
  }
  