@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400;500;600;700&display=swap");
* {
  font-family: "Nunito", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  border: none;
  text-decoration: none;
  transition: all 0.02s linear;
}

html::-webkit-scrollbar {
  width: 1rem;
}
html::-webkit-scrollbar-track {
  background-color: transparent;
}
html::-webkit-scrollbar-thumb {
  background-color: #3C91E6;
}

section {
  padding: 1rem 10%;
  direction: rtl;
}


.btn {
  display: inline-block;
  margin-top: 1rem;
  padding: 1rem 2rem;
  font-size: 1.2rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  background: #c8def8;
  color: #3C91E6;
  cursor: pointer;
  text-transform: capitalize;
}
.btn:hover {
  background: #3C91E6;
  color: #fff;
}

.header {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  background: #fff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
  padding: 1rem 6%;
  display: flex;
  align-items: center;
  z-index: 1000;
  direction: rtl;

}
.header .logo {
  margin-left: auto;
  text-transform: capitalize;
  color: #3C91E6;
  font-weight: bolder;
}
.header .logo img {
  text-align: center;
  width: 3rem;
  color: #3C91E6;
}
.header .navbar {
  position: relative;
}
.header .navbar #close-navbar {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  font-size: 2rem;
  cursor: pointer;
  color: #444;
  display: none;
}
.header .navbar #close-navbar:hover {
  transform: rotate(-90deg);
}
.header .navbar a {
  margin-left: 2rem;
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #444;
}
.header .navbar a:hover {
  color: #3C91E6;
}
.header .icons div {
  cursor: pointer;
  font-size: 1.5rem;
  color: #444;
  margin-right: 2rem;
}
.header .icons div:hover {
  color: #3C91E6;
}
.header #menu-btn {
  display: none;
}

.message {
  position: sticky;
  top: 0;
  width: 80%;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1000;
  background-color: rgba(241, 73, 73, 0.757);
  margin: 10px auto;
}

.message span {
  color: #fff;
  font-size: 1rem;
}

.message i {
  color: red;
  font-size: 2.5rem;
  cursor: pointer;
}

.message i:hover {
  color: #fff;
}

.account-form {
  position: fixed;
  top: 0;
  left: -105%;
  width: 40rem;
  background: #fff;
  display: flex;
  flex-flow: column;
  gap: 2rem;
  justify-content: center;
  height: 100%;
  z-index: 1200;
  padding: 2rem;
  text-align: center;
}
.account-form.active {
  left: 0;
  box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.8);
}
.account-form #close-form {
  position: absolute;
  top: 1.5rem;
  left: 2rem;
  font-size: 4rem;
  cursor: pointer;
  color: #444;
}
.account-form #close-form:hover {
  transform: rotate(-90deg);
  color: red;
}
.account-form form {
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  padding: 2rem;
  display: none;
}
.account-form form.active {
  display: block;
}
.account-form form h3 {
  font-size: 2.5rem;
  text-transform: capitalize;
  color: #444;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}
.account-form form .box {
  width: 100%;
  padding: 1.2rem 1.4rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  margin: 0.7rem 0;
}
.account-form form .flex {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}
.account-form form .flex label {
  font-size: 1.5rem;
  color: #777;
  cursor: pointer;
}
.account-form form .flex a {
  font-size: 1.5rem;
  color: #777;
  margin-right: auto;
}
.account-form form .flex a:hover {
  text-decoration: underline;
  color: #3C91E6;
}
.account-form form .btn {
  width: 100%;
}
.account-form .buttons .btn {
  margin: 0 0.5rem;
}
.account-form .buttons .btn.active {
  background: #3C91E6;
  color: #fff;
}

.account-form1 {
  position: relative;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background: #fff;
  display: flex;
  flex-flow: column;
  gap: 2rem;
  justify-content: center;
  height: auto;
  z-index: 1200;
  padding: 2rem;
  text-align: center;
  margin: 5% auto;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}

.account-form1 #close-form {
  position: absolute;
  top: 1.5rem;
  right: 2rem;
  font-size: 2rem;
  cursor: pointer;
  color: #444;
}

.account-form1 #close-form:hover {
  transform: rotate(-90deg);
  color: red;
  transition: all 0.3s ease;
}

.account-form1 form {
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  padding: 2rem;
  display: none;
  border-radius: 8px;
}

.account-form1 form.active {
  display: block;
}

.account-form1 form h3 {
  font-size: 2.5rem;
  text-transform: uppercase;
  color: #444;
  padding-bottom: 0.5rem;
}

.account-form1 form .box {
  width: 100%;
  padding: 1rem 1.2rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  margin: 0.7rem 0;
  border-radius: 5px;
}

.account-form1 form .box:focus {
  outline: none;
  border-color: #3C91E6;
}

.account-form1 form .flex {
  padding: 1rem 0;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.account-form1 form .flex label {
  font-size: 1.5rem;
  color: #777;
  cursor: pointer;
}

.account-form1 form .flex a {
  font-size: 1.5rem;
  color: #777;
  margin-right: auto;
}

.account-form1 form .flex a:hover {
  text-decoration: underline;
  color: #3C91E6;
}

.account-form1 form .btn {
  width: 100%;
  padding: 1rem;
  font-size: 1.6rem;
  border: none;
  background-color: #3C91E6;
  color: white;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.account-form1 form .btn:hover {
  background-color: #357ABD;
}

.account-form1 .buttons .btn {
  margin: 0 0.5rem;
  font-size: 1.4rem;
  padding: 0.8rem 1.2rem;
  background-color: #f0f0f0;
  color: #444;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.account-form1 .buttons .btn.active {
  background: #3C91E6;
  color: #fff;
}

.account-form1 .buttons .btn:hover {
  background-color: #e0e0e0;
}


/* Landing.css */

.home {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%; /* Full viewport height */
  background: linear-gradient(135deg, #e8f0fe, #3C91E6); /* Gradient background */
  text-align: center;
  direction: rtl; /* Right-to-left for Arabic */
  padding: 2rem;
}

.home .content {
  background-color: #ffffff; /* White background for content */
  padding: 2rem 3rem;
  border-radius: 20px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.1); /* Elevated shadow for modern feel */
  max-width: 600px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.home .content:hover {
  transform: translateY(-10px); /* Subtle hover animation */
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15);
}
.home h2 {
  font-size: 3rem; /* Larger, prominent size */
  color: #333; /* Neutral color for readability */
  background: linear-gradient(90deg, #3C91E6, #04509b); /* Gradient text for modern look */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* Ensure gradient shows on text */
  font-weight: 700; /* Bold weight for emphasis */
  margin-bottom: 1.5rem;
  letter-spacing: 1.5px; /* Improve spacing between characters */
  text-transform: uppercase; /* All uppercase for a strong statement */
  line-height: 1.2; /* Slightly tighter line spacing for a modern feel */
  transition: transform 0.3s ease; /* Add subtle animation for interactivity */
}

.home h2:hover {
  transform: scale(1.05); /* Slight scaling on hover */
}

.home p {
  font-size: 1.2rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 2rem;
}

.home .btn1 {
  display: inline-block;
  margin: 0.5rem;
  padding: 1rem 2rem;
  background-color: #3C91E6; /* Primary button color */
  color: #fff;
  border: none;
  border-radius: 30px; /* Modern rounded buttons */
  font-size: 1.2rem;
  text-decoration: none;
  transition: background 0.3s ease, box-shadow 0.3s ease; /* إضافة تأثير الانتقال للظل */
  box-shadow: 5px 10px 12px rgba(0, 0, 0, 0.434); /* تأثير الظل */
}

.home .btn1:hover {
  background-color: #2a6a9c; /* Darker shade on hover */
  transform: translateY(-5px); /* Slight elevation on hover */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
}



.subjects .heading {
    font-size: 2rem;
    text-align: center;
    margin-bottom: 3rem;

  }

.subjects .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 1rem;
}
.subjects .box-container .box {
  padding: 2rem 1rem;
  text-align: center;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  background: #eef3f9;
  cursor: pointer;
}
.subjects .box-container .box:hover {
  background: #3C91E6;
}
.subjects .box-container .box:hover h3 {
  color: #fff;
}
.subjects .box-container .box:hover p {
  color: #eee;
}
.subjects .box-container .box img {
  height: 7rem;
  margin-bottom: 0.7rem;
}
.subjects .box-container .box h3 {
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #444;
  padding: 0.5rem 0;
}
.subjects .box-container .box p {
  font-size: 1rem;
  line-height: 2;
  color: #777;
}


        /* Specific styles for the feedback page */
        .feedback {
            padding: 40px 0;
            background: #f4f4f4;
        }

        .feedback .container {
            max-width: 1200px;
            margin: 0 auto;
            padding: 20px;
        }

        .feedback h1.heading {
            font-size: 2em;
            margin-bottom: 20px;
            text-align: center;
            color: #333;
        }

        .feedback .feedback-form {
            background: #fff;
            border-radius: 8px;
            box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
            padding: 20px;
            max-width: 800px;
            margin: 0 auto;
        }

        .feedback .feedback-form h2 {
            margin-bottom: 20px;
            font-size: 1.5em;
            color: #333;
        }

        .feedback .feedback-form input[type="text"],
        .feedback .feedback-form input[type="email"],
        .feedback .feedback-form textarea {
            width: 100%;
            padding: 12px;
            margin-bottom: 15px;
            border: 1px solid #ddd;
            border-radius: 4px;
        }

        .feedback .feedback-form textarea {
            height: 150px;
            resize: vertical;
        }

        .feedback .feedback-form input[type="submit"] {
            background: #007bff;
            color: #fff;
            border: none;
            padding: 12px 20px;
            border-radius: 4px;
            font-size: 1em;
            cursor: pointer;
            transition: background 0.3s ease;
        }

        .feedback .feedback-form input[type="submit"]:hover {
            background: #0056b3;
        }

   /* Specific styles for the FAQ page */
   .faq {
    padding: 40px 0;
    background: #f4f4f4;
}

.faq .container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
}

.faq h1.heading {
    font-size: 2em;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
}

.faq .faq-card {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
    overflow: hidden;
    transition: all 0.3s ease;
}

.faq .faq-card h2 {
    margin: 0;
    padding: 15px;
    background: #007bff;
    color: #fff;
    font-size: 1.2em;
    cursor: pointer;
}

.faq .faq-card .content {
    padding: 15px;
    display: none;
    background: #f9f9f9;
}

.faq .faq-card.open .content {
    display: block;
}

.faq .faq-card.open h2 {
    background: #0056b3;
}

.help-center {
    padding: 2rem;
}

.help-center .section-title {
    font-size: 3rem;
    text-align: center;
    color: #444;
    margin-bottom: 2rem;
}

.help-center .faq {
    margin-bottom: 4rem;
}

.help-center .faq h3 {
    font-size: 2rem;
    color: #3C91E6;
    cursor: pointer;
    margin-bottom: 1rem;
}

.help-center .faq p {
    font-size: 1.5rem;
    color: #777;
    line-height: 1.6;
    display: none;
    margin-bottom: 1rem;
}

.help-center .faq.active p {
    display: block;
}

.help-center .contact-options {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
    gap: 2rem;
    margin-bottom: 4rem;
}

.help-center .contact-options .option {
    text-align: center;
    padding: 3rem 2rem;
    border: 0.1rem solid rgba(0, 0, 0, 0.2);
    background: #f1f5fa;
}

.help-center .contact-options .option i {
    height: 6rem;
    width: 6rem;
    line-height: 6rem;
    font-size: 2rem;
    border-radius: 50%;
    margin-bottom: 0.5rem;
    color: #fff;
    background: #3C91E6;
}

.help-center .contact-options .option h3 {
    font-size: 2rem;
    text-transform: capitalize;
    color: #444;
    padding: 0.5rem 0;
}

.help-center .contact-options .option p {
    font-size: 1.5rem;
    line-height: 2;
    color: #777;
}

.help-center .resources {
    text-align: center;
}

.help-center .resources a {
    display: inline-block;
    margin: 0.5rem;
    padding: 1rem 2rem;
    font-size: 1.6rem;
    color: #3C91E6;
    border: 0.1rem solid #3C91E6;
    border-radius: 0.5rem;
    transition: background 0.3s, color 0.3s;
}

.help-center .resources a:hover {
    background: #3C91E6;
    color: #fff;
}

.about {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3rem;
}
.about .images {
  flex: 1 1 40rem;
}
.about .images img {
  width: 100%;
}
.about .content {
  flex: 1 1 40rem;
}
.about .content .about-title {
  font-size: 3rem;
  text-transform: capitalize;
  color: #444;
  margin-bottom: 2rem;
  text-align: center;
}
.about .content .about-title1{
    font-size: 2rem;
    text-transform: capitalize;
    color: #444;
    margin-bottom: 2rem;
  }
.about .content p {
    font-family: 'Georgia', serif;
    font-size: 1.5rem;
    line-height: 1.7;
    color: #555;
    font-weight: bold; /* نص عريض */
    margin: 0 0 1em;
}
.about .content .icons-container {
  margin-top: 1rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(16rem, 1fr));
  gap: 2rem;
}
.about .content .icons-container .icons {
  text-align: center;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  background: #eef3f9;
  padding: 3rem 2rem;
}
.about .content .icons-container .icons img {
  height: 5rem;
  margin-bottom: 0.5rem;
}
.about .content .icons-container .icons h3 {
  padding: 0.5rem 0;
  font-size: 3rem;
  text-transform: capitalize;
  color: #444;
}
.about .content .icons-container .icons span {
  font-size: 1.5rem;
  line-height: 2;
  color: #777;
}

.contact .icons-container {
  margin-bottom: 3rem;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
  gap: 2rem;
}
.contact .icons-container .icons {
  text-align: center;
  padding: 3rem 2rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  background: #f1f5fa;
}
.contact .icons-container .icons i {
  height: 6rem;
  width: 6rem;
  line-height: 6rem;
  font-size: 2rem;
  border-radius: 50%;
  margin-bottom: 0.5rem;
  color: #fff;
  background: #3C91E6;
}
.contact .icons-container .icons h3 {
  font-size: 2rem;
  text-transform: capitalize;
  color: #444;
  padding: 0.5rem 0;
}
.contact .icons-container .icons p {
  font-size: 1.5rem;
  line-height: 2;
  color: #777;
}
.contact .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
}
.contact .row .image {
  flex: 1 1 40rem;
}
.contact .row .image img {
  width: 100%;
}
.contact .row form {
  flex: 1 1 40rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  padding: 2rem;
}
.contact .row form h3 {
  font-size: 2.5rem;
  text-transform: capitalize;
  color: #444;
  padding-bottom: 1rem;
}
.contact .row form .box {
  width: 100%;
  padding: 1.2rem 1.4rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  font-size: 1.6rem;
  margin: 0.7rem 0;
}
.contact .row form .box:focus {
  background: #3C91E6;
  color: #fff;
}
.contact .row form .box:focus::placeholder {
  color: #eee;
}
.contact .row form textarea {
  height: 15rem;
  resize: none;
}

.footer {
  background: #f1f5fa;
}
.footer .box-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 2rem;
}
.footer .box-container .box h3 {
  font-size: 1.5rem;
  text-transform: capitalize;
  color: #444;
  padding: 0.3rem 0;
}
.footer .box-container .box h3 a {
  text-align: center;
  align-items: center;
  font-size: 1.2rem;
  width: 2rem;
  color: #3C91E6;
}
.footer .box-container .box .share {
  margin-top: 1rem;
}
.footer .box-container .box .share a {
  height: 4.5rem;
  width: 4.5rem;
  line-height: 4.5rem;
  font-size: 1rem;
  background-color: #3C91E6;
  color: #fff;
  margin-left: 0.3rem;
  text-align: center;
}
.footer .box-container .box .share a:hover {
  background-color: #444;
}
.footer .box-container .box .link {
  font-size: 1.2rem;
  line-height: 2;
  color: #777;
  padding: 0.5rem 0;
  display: block;
}
.footer .box-container .box .link:hover {
  color: #3C91E6;
  text-decoration: underline;
}
.footer .box-container .box p {
  font-size: 1.2rem;
  line-height: 2;
  color: #777;
  padding: 1rem 0;
}
.footer .box-container .box .email {
  width: 100%;
  padding: 0.8rem 1rem;
  font-size: 1rem;
  border: 0.1rem solid rgba(0, 0, 0, 0.2);
  margin-bottom: 1rem;
}
.footer .credit {
  text-align: center;
  margin-top: 1rem;
  padding-top: 1rem;
  font-size: 1.2rem;
  text-transform: capitalize;
  color: #444;
  border-top: 0.1rem solid rgba(0, 0, 0, 0.2);
}
.footer .credit span {
  color: #3C91E6;
}

@media (max-width: 1200px) {
  .header {
    padding: 2rem 5%;
  }
  section {
    padding: 3rem 5%;
  }
}
@media (max-width: 991px) {
  html {
    font-size: 55%;
  }
  .header {
    padding: 2rem;
  }
  section {
    padding: 3rem 2rem;
  }
}
@media (max-width: 768px) {
  .header #menu-btn {
    display: inline-block;
  }
  .header .navbar {
    position: fixed;
    top: 0;
    left: -105%;
    width: 18rem;
    background: #fff;
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: center;
    z-index: 1200;
  }
  .header .navbar #close-navbar {
    display: block;
  }
  .header .navbar.active {
    box-shadow: 0 0 0 100vw rgba(0, 0, 0, 0.8);
    left: 0;
  }
  .header .navbar a {
    display: block;
    margin: 1rem 0;
    text-align: center;
    font-size: 1.5rem;
  }
}
.home .slide .content h3 {
  font-size: 3rem;
}
.account-form1 {
    width: 40rem;
}

@media (max-width: 450px) {
  html {
    font-size: 50%;
  }
  .heading {
    font-size: 3rem;
  }
  #close-navbar {
    display: block;
  }
  .about .content .about-title {
    font-size: 4rem;
  }
}

@media (max-width: 768px) {
  .home .content {
    padding: 1.5rem;
  }

  .home h2 {
    font-size: 1.6rem;
  }

  .home p {
    font-size: 1rem;
  }

  .home .btn1 {
    font-size: 1.2rem;
    padding: 0.8rem 1.5rem;
    font-weight: 800;
  }
}